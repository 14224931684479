import { Injectable } from '@angular/core';
import { createClient, SupabaseClient } from '@supabase/supabase-js'
import { environment } from '../../environments/environment';

export interface Message {
  fromName: string;
  subject: string;
  date: string;
  id: number;
  read: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class DataService {

  public supabase: SupabaseClient;

  constructor() {
    this.supabase = createClient(environment.supabaseUrl, environment.supabaseKey)
  }

  public async Tac(): Promise<any> {
    return (await this.supabase.from('toc').select('*').single())
  }

  public async Settings(): Promise<JSettings> {
    if (localStorage.getItem("SETTINGSEXPIRETIME") == null) {
      localStorage.removeItem("SETTINGS");
    } else if (Number.parseInt(localStorage.getItem("SETTINGSEXPIRETIME")) < Date.now()) {
      localStorage.removeItem("SETTINGS");
    }

    if (localStorage.getItem("SETTINGS") == null) {
      localStorage.setItem("SETTINGSEXPIRETIME", (Date.now() + environment.SettingsExpiretime).toString())
      let v = await this.supabase.from('settings').select('*', { count: 'exact' });
      if (v.count == null || v.count == 0) {
        let settingsObject: JSettings = { reddays: 5, amberdays: 30, lang1name: 'English', lang1code: 'EN', lang2enabled: false, lang2name: '', lang2code: '', lang1currency: '€', lang2currency: '€', defaulttax: 0.21 }
        localStorage.setItem("SETTINGS", JSON.stringify(settingsObject))
        return settingsObject;
      } else {
        let settingsObject: JSettings = {
          id: v.data[0].id,
          reddays: v.data[0].reddays,
          amberdays: v.data[0].amberdays,
          lang1name: v.data[0].lang1name,
          lang1code: v.data[0].lang1code,
          lang2enabled: v.data[0].lang2enabled,
          lang2name: v.data[0].lang2name,
          lang2code: v.data[0].lang2code,
          lang1currency: v.data[0].lang1currency,
          lang2currency: v.data[0].lang2currency,
          defaulttax: v.data[0].defaulttax
        }
        localStorage.setItem("SETTINGS", JSON.stringify(settingsObject))
        return settingsObject;
      }
    } else {
      let sob = JSON.parse(localStorage.getItem("SETTINGS"))
      sob.lang2enabled = (String(sob.lang2enabled).toLowerCase() == "true");
      return sob;
    }
  }

}
export interface JSettings {
  id?: number;
  reddays: number;
  amberdays: number;
  lang1name: string
  lang1code: string
  lang1currency: string
  lang2enabled: boolean
  lang2name: string
  lang2code: string
  lang2currency: string
  defaulttax: number;
}

export interface PortalSettings {
  expectedHours: number;
  expectedMinutes: number;
  pickupHour: number;
  pickupMinutes: number;
  includeSunday: boolean;
  includeMonday: boolean;
  includeTuesday: boolean;
  includeWednesday: boolean;
  includeThursday: boolean;
  includeFriday: boolean;
  includeSaturday: boolean;
}