import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'tax'
})
export class TaxPipe implements PipeTransform {

    public transform(value: number, tax: number, taxonly: boolean = false): string {
        const a = Math.round(value * (100 + tax)) / 100;
        if(Number.isNaN(a)) {
            return '0';
        }
        const price = (taxonly ? Math.round((a-value) * 100) / 100 : a).toString();
        const dotidx = price.indexOf('.');
        if (dotidx < 0) {
            return price + '.00';
        } else if (dotidx>price.length-3) {
            return price + '0';
        }
        return price;
    }
}
