import { Component, Input, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { DataService } from '../services/data.service';

@Component({
  selector: 'app-tacdialog',
  templateUrl: './tacdialog.component.html',
  styleUrls: ['./tacdialog.component.scss'],
})
export class TacDialog implements OnInit {

  @Input('isPrimaryLang') isPrimaryLang: boolean = true;
  thedata = undefined

  constructor(private modalController: ModalController, private supa: DataService, private alertController: AlertController) { }

  async presentAlert() {
    const alert = await this.alertController.create({
      header: 'There was a problem',
      subHeader: 'Could not load the terms & conditions',
      message: 'Please contact our customer service.',
      buttons: ['OK']
    });
  
    await alert.present();
    await alert.onDidDismiss();
    this.dismissModal();
  }

  ngOnInit() {
    this.supa.Tac().then(v => {
      if(v.error) {
        this.presentAlert();
      } else {
        this.thedata = v.data;
      }
    })
  }

  dismissModal() {
    this.modalController.dismiss();
  }

  accept() {
    this.modalController.dismiss(true);
  }

}
