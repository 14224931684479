import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'colsizes',
  template: `
  <ion-chip color="primary" class="ion-hide-sm-up" mode="ios" outline="true">
    <ion-label>Current width size: "Size"</ion-label>
  </ion-chip>
  <ion-chip color="primary" class="ion-hide-sm-down ion-hide-md-up" mode="ios" outline="true">
    <ion-label>Current width size: "Sm"</ion-label>
  </ion-chip>
  <ion-chip color="primary" class="ion-hide-md-down ion-hide-lg-up" mode="ios" outline="true">
    <ion-label>Current width size: "Md"</ion-label>
  </ion-chip>
  <ion-chip color="primary" class="ion-hide-lg-down ion-hide-xl-up" mode="ios" outline="true">
    <ion-label>Current width size: "Lg"</ion-label>
  </ion-chip>
  <ion-chip color="primary" mode="ios" class="ion-hide-xl-down" outline="true">
    <ion-label>Current width size: "Xl"</ion-label>
  </ion-chip>
  `,
  styles: [],
})
export class SizehelperComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
